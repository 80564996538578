import Vue from "vue";
import { GridPlugin, Edit, Sort, Toolbar, CommandColumn } from "@syncfusion/ej2-vue-grids";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
import OfficeEditTemplate from "@/components/Templates/Service/OfficeEditTemplate.vue";
import { mapGetters } from "vuex";
Vue.use(GridPlugin);
export default Vue.extend({
    mixins: [TitleMixin, SpinnerMixin],
    data() {
        return {
            title: this.$t("services.create.title"),
            price: undefined,
            service: {
                name: "",
                description: "",
                multipleParticipants: false,
                roomRequired: false,
                roomMandatory: false,
                equipmentRequired: false,
                equipmentMandatory: false,
                offices: []
            },
            validationOptions: {
                rules: {
                    name: {
                        required: true,
                        rangeLength: [3, 100]
                    },
                    description: {
                        maxLength: 1000
                    },
                    multipleParticipants: {
                        required: true
                    },
                    roomRequired: {
                        required: true
                    },
                    roomMandatory: {
                        required: true
                    },
                    equipmentRequired: {
                        required: true
                    },
                    equipmentMandatory: {
                        required: true
                    }
                }
            },
            officesGrid: {
                editSettings: {
                    allowEditing: true,
                    allowAdding: true,
                    allowDeleting: true,
                    mode: "Dialog",
                    template: function () {
                        return {
                            template: Vue.component("office-service-item", OfficeEditTemplate)
                        };
                    }
                },
                commands: [
                    /*
                    {
                      type: "Edit",
                      buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" }
                    },
                    */
                    {
                        type: "Delete",
                        buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" }
                    }
                ],
                toolbar: ["Add"],
                sortOptions: {
                    columns: [{ field: "officeName", direction: "Ascending" }]
                },
                validationOptions: {
                    rules: {
                        officeId: {
                            required: true
                        },
                        price: {
                            required: true,
                            number: true,
                            min: 0
                        },
                    }
                }
            }
        };
    },
    provide: {
        grid: [Sort, Edit, Toolbar, CommandColumn]
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices",
            getSingleOfficeId: "account/getSingleOfficeId"
        }),
        roomMandatoryEnabled() {
            return this.service.roomRequired === true;
        },
        equipmentMandatoryEnabled() {
            return this.service.equipmentRequired === true;
        }
    },
    methods: {
        onPriceValueChanged(value) {
            if (this.hasMultipleOffices)
                return;
            const userOfficeId = this.getSingleOfficeId;
            const serviceOffice = this.service.offices.find(serviceOffice => serviceOffice.officeId === userOfficeId);
            if (serviceOffice == undefined) {
                if (value != null) {
                    this.service.offices.push({
                        officeId: userOfficeId,
                        officeName: "",
                        price: value
                    });
                }
            }
            else {
                if (value != null)
                    serviceOffice.price = value;
                else
                    this.service.offices = this.service.offices.filter(serviceOffice => serviceOffice.officeId !== userOfficeId);
            }
        },
        create() {
            return this.$store.dispatch("service/create", {
                service: this.service
            });
        },
        createSuccess() {
            this.$toast.showSuccessToast(this.$t("services.create.toastSuccessTitle"), this.$t("services.create.toastSuccessContent", {
                name: this.service.name
            }));
            return this.$router.push("/administration/services");
        },
        cancel() {
            return this.$router.push("/administration/services");
        },
        officesGridActionBegin(args) {
            if (args.requestType === "save") {
                args.data["officeName"] = args.form.querySelector("#officeId").value;
            }
        },
        officesGridActionComplete(args) {
            if (args.requestType === "beginEdit" || args.requestType === "add") {
                // Add Validation Rules
                const serviceOffices = this.service.offices;
                args.form.ej2_instances[0].addRules("officeId", {
                    exists: [
                        function (args) {
                            const officeIdOld = args.element.form.querySelector("#officeIdOld").value;
                            if (args.value == officeIdOld)
                                return true;
                            return !serviceOffices.some(office => office.officeId == args.value);
                        },
                        this.$t("offices.officeExists")
                    ]
                });
                args.dialog.width = 380;
                if (args.requestType === "add") {
                    args.dialog.header = this.$t("offices.newOffice");
                }
                else {
                    args.dialog.header = this.$t("offices.editOffice", {
                        name: args.rowData["officeName"]
                    });
                }
            }
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            const offices = await this.$store.dispatch("office/getAll", {
                load: true
            });
            this.service.offices = [];
            this.hideSpinner();
        }
        catch (errors) {
            this.hideSpinner();
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
            await this.$router.push("/administration/services");
        }
    }
});
